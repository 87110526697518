<template>
  <v-card elevation="0" max-height="800px" class="pb-6 pt-2">
    <v-row no-gutters class="px-6 table__header">
      <v-col xl="4" lg="4" md="6" sm="5" cols="12" class="table__searcher pl-2">
        <v-text-field
          v-model="search"
          :label="$i18n.t('TABLE.searchLabel')"
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        />
      </v-col>
      <v-col
        xl="3"
        lg="3"
        md="4"
        sm="5"
        cols="12"
        class="pl-2"
        v-if="acceptCompanies"
      >
        <v-autocomplete
          v-if="companies && this.$store.state.Auth.token.role === 'superuser'"
          filled
          rounded
          @change="getCompany"
          :value="companySelected"
          :items="companiesNames"
          :search-input.sync="searchCompany"
          hide-no-data
          hide-selected
          item-text="Description"
          item-value="API"
          :label="$i18n.t('COMPANY.SELECT')"
          :placeholder="$i18n.t('GENERAL.search')"
          prepend-inner-icon="mdi-domain"
          return-object
        />
      </v-col>
      <v-col v-if="acceptCompanies">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                fixed
                bottom
                right
                color="green"
                dark
                fab
                @click="openUpload"
                class="add-new__floating"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>{{ $i18n.t("TABLE.addNew") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="green"
              dark
              fab
              @click="openUpload"
              class="add-new__button"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("TABLE.userBatchUpload") }}</span>
        </v-tooltip>
        <ImportListTool
          v-model="uploadUsers"
          type="json"
          :return-filename="false"
          :columns="columns"
          @result="createUsers"
        ></ImportListTool>
      </v-col>
      <v-col
        class="text-end"
        v-if="
          acceptCompanies || this.$store.state.Auth.token.role === 'superuser'
        "
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                fixed
                bottom
                right
                color="green"
                dark
                fab
                @click="$emit('add')"
                class="add-new__floating"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>{{ $i18n.t("TABLE.addNew") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="green"
              dark
              fab
              @click="$emit('add')"
              class="add-new__button"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.t("TABLE.addNew") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="table-overflow">
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="data"
          hide-default-footer
          :items-per-page="-1"
          @click:row="rowSelect"
          :search="search"
        >
          <template v-slot:item.id="{ item }">
            {{ item.id }}
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="item.isLocked" v-bind="attrs" v-on="on" x-small>
                  mdi-lock
                </v-icon>
              </template>
              <span>{{ $i18n.t("TABLE.lockedUser") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data v-if="icon === 'mdi-account'">
            <v-alert
              :value="true"
              color="warning"
              icon="mdi-alert-circle"
              v-if="companySelected === ''"
            >
              {{ $i18n.t("COMPANY.SELECTMESSAGE") }}
            </v-alert>
            <v-alert
              :value="true"
              color="error"
              icon="mdi-alert-circle"
              v-else-if="data.length === 0"
            >
              {{ $i18n.t("TABLE.noData") }}
            </v-alert>
          </template>
        </v-data-table>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import ImportListTool from "./ImportListTool.vue";
export default {
  data() {
    return {
      search: "",
      searchCompany: "",
      companySelected: "",
      uploadUsers: false,
    };
  },
  components: {
    ImportListTool,
  },
  computed: {
    companiesNames() {
      const x = this.companies.map((c) => {
        return c.name;
      });
      return x;
    },
    columns() {
      const isSuper = this.$store.state.Auth.token.role === "superuser";
      const cols = [
        {
          value: "id",
          text: "Id",
          validation: function (e) {
            const rgx = /.*[\s@].*/;
            return (!!e && !rgx.test(e)) || "id must not contain spaces or @";
          },
          required: true,
        },
        {
          value: "fullname",
          text: "Fullname",
          required: true,
        },
        {
          value: "password",
          text: "Password",
          required: true,
        },
        {
          value: "type",
          text: "Type",
          validation: function (e) {
            const rgxSuper = /^agent$|^superuser$|^admin$/;
            const rgxAdmin = /^agent$|^admin$/;
            return (
              (!!e && ((isSuper && rgxSuper.test(e)) || rgxAdmin.test(e))) ||
              "Invalid user type"
            );
          },
          required: true,
        },
        {
          value: "externalid",
          text: "External Id",
        },
        {
          value: "email",
          text: "Email",
          validation: function (e) {
            const rgx = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
            return (!!e && rgx.test(e)) || "not a valid email";
          },
        },
        {
          value: "phone",
          text: "Phone",
        },
      ];
      return cols;
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    acceptCompanies: {
      type: Boolean,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    rowSelect(e) {
      this.$emit("save", e);
    },
    openUpload() {
      this.uploadUsers = true;
    },

    createUsers(batch) {
      this.$emit("addBatch", batch);
    },
    getCompany(e) {
      let companyId;
      this.companies.forEach((c) => {
        if (c.name === e) {
          companyId = c.id;
        }
      });
      this.$emit("getCompany", companyId);
    },
  },
};
</script>

<style scoped lang="scss">
.add-new__button {
  display: none;
}

@media (min-width: 1264px) {
  .table-overflow {
    padding: 0 20px;
    margin: 0 20px;
    overflow-y: auto;
    max-height: 550px;
  }
  .table__header {
    padding-top: 20px !important;
  }
  .table__searcher {
    padding-left: 40px !important;
  }
}
@media (min-width: 600px) {
  .table__header {
    padding-top: 20px;
  }
  .table__searcher {
    padding-left: 5px;
  }
  .add-new__floating {
    display: none;
  }
  .add-new__button {
    display: inline-block;
  }
  .table-overflow {
    overflow-y: auto;
    max-height: 550px;
  }
}
</style>
